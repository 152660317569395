import { IForgotPasswordResponse } from '@/interface/forgot-password-response.interface'
import { IRestPasswordResponse } from '@/interface/rest-password-response.interface'
import { IAddress, IUser } from '@/interface/user.interface'
import { CreateProfileData } from '@/pages/auth/create-profile'
import { Verification } from '@/pages/auth/verification-code'
import { VerificationCodeData } from '@/pages/auth/verification-method'
import { RegisterProps } from '@/pages/register'
import httpService from '@/services/httpService'
import useSWR from 'swr'
import { logger } from '@/services/index'
import { useRouter } from 'next/router'

export const useAuthService = () => {
  const router = useRouter()
  // const [isLoading, setIsLoading] = useState(false)

  const {
    data: user,
    mutate,
    error,
  } = useSWR('/auth/user', (url) => {
    const token = localStorage.getItem('token')
    if (!token) {
      console.debug('token exist!')
      return
    } else
      return httpService
        .get(url)
        .then(async (res: any) => {
          return res.data
        })
        .catch(async (error) => {
          if (error.response.status === 401) {
            await signOut()
          } else {
            logger.log(error)
          }
          throw error
        })
  })

  const signIn = async (username: string, password: string): Promise<boolean> => {
    const res: { data: { token: string; refreshToken: string } } = await httpService.post('auth/login', {
      username,
      password,
    })
    localStorage.setItem('token', res.data.token)
    await mutate('/auth/user')
    return Promise.resolve(true)
  }

  const register = async (model: RegisterProps): Promise<number> => {
    const res: { data: { token: string; refreshToken: string; id: number } } = await httpService.post(
      'auth/register',
      model,
    )
    localStorage.setItem('token', res.data.token)
    await mutate('/auth/user')
    return Promise.resolve(res.data.id)
  }

  const sendVerificationCode = async (data: VerificationCodeData): Promise<boolean> => {
    const res: any = await httpService.post('auth/send-verification-code', data)
    return Promise.resolve(res)
  }
  const checkVerificationCode = async (data: Verification): Promise<boolean> => {
    const res: any = await httpService.post('auth/verify', data)
    return Promise.resolve(res)
  }
  const createProfile = async (data: CreateProfileData): Promise<boolean> => {
    const res: any = await httpService.post('v1/selfPatient/profile', data)
    await mutate('/auth/user')
    return Promise.resolve(res)
  }
  const riskFactorQuestions = async (): Promise<boolean> => {
    const res: any = await httpService.get('v1/selfPatient/risk-calculator/questions')
    return Promise.resolve(res)
  }

  const signOut = async () => {
    localStorage.removeItem('token')
    await mutate({})
    await router.replace('/')
  }

  const forgotPassword = async (username: string): Promise<IForgotPasswordResponse> => {
    const res: IForgotPasswordResponse = await httpService.post('auth/forgot-password', { username })
    return Promise.resolve(res)
  }

  const resetPassword = async (
    newPassword: string,
    token: any,
    type: string | null,
  ): Promise<IRestPasswordResponse> => {
    const res: IRestPasswordResponse = await httpService.post('auth/reset-password', { newPassword, token, type })
    return Promise.resolve(res)
  }

  const updateProfile = async (user: IUser): Promise<boolean> => {
    await httpService.put('/v1/user', {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: Number(user.mobile),
    })
    await mutate('/auth/user')
    return Promise.resolve(true)
  }

  const addAddress = async (address: IAddress): Promise<boolean> => {
    await httpService.post('/v1/user/address', {
      zipCode: address.zipCode,
      cityId: address.city?.id,
      street1: address.street1,
      street2: address.street2,
      googleMapLink: address.googleMapLink,
    })
    await mutate('/auth/user')
    return Promise.resolve(true)
  }

  const updateAddress = async (address: IAddress, addressId: number): Promise<boolean> => {
    await httpService.put(`/v1/user/address/${addressId}`, {
      zipCode: address.zipCode,
      cityId: address.city?.id,
      street1: address.street1,
      street2: address.street2,
      googleMapLink: address.googleMapLink,
    })
    await mutate('/auth/user')
    return Promise.resolve(true)
  }

  const updatePassword = async (
    oldPassword: string,
    newPassword: string,
  ): Promise<{ success: boolean; message: string }> => {
    const res: { success: boolean; message: string } = await httpService.put('/auth/password', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
    await mutate('/auth/user')
    return Promise.resolve(res)
  }

  const getAddresses = async (): Promise<IAddress[]> => {
    const res: { data: IAddress[] } = await httpService.get('/v1/user/address')
    return Promise.resolve(res.data)
  }

  return {
    signIn,
    forgotPassword,
    resetPassword,
    signOut,
    getAddresses,
    updateProfile,
    addAddress,
    updateAddress,
    updatePassword,
    register,
    sendVerificationCode,
    checkVerificationCode,
    createProfile,
    riskFactorQuestions,
    user,
    error,
    // isLoading,
  }
}
