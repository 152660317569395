function FallbackComponent() {
  return (
    <>
      <div className="">
        <div className="text-center">
          <h1 className="mt-5 text-[36px] font-bold text-slate-800 lg:text-[50px]">Oops!</h1>
          <p className="text-slate-600 my-5 lg:text-lg">
            Oops something went wrong. Try to refresh this page or feel free to contact us if the problem presists.
          </p>
          <a
            className={
              'bg-blue hover:bg-darkBlue text-white font-bold py-2 px-4 border-b-4 border-darkBlue hover:border-darkBlue rounded mt-5'
            }
            href={'/'}
          >
            Go to Home
          </a>
        </div>
      </div>
    </>
  )
}

export default FallbackComponent
