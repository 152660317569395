// import * as React from 'react'
import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AppProps } from 'next/app'
import Head from 'next/head'
import theme from '../components/layout/theme'
import createEmotionCache from '../createEmotionCache'
import { useAuthService } from '@/services/auth.service'
import { AuthContextProvider } from '@/context/auth-context'
import { useConfigService } from '@/services/config.service'
import { ConfigContextProvider } from '@/context/config-context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as Sentry from '@sentry/nextjs'
import FallbackComponent from './../components/fallback'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const authService = useAuthService()
  const configService = useConfigService()
  return (
    <CacheProvider value={emotionCache}>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <Head>
          <title>Livelong panel</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <meta name="apple-itunes-app" content="app-id=6478452404" />
        </Head>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <ConfigContextProvider value={configService}>
            <AuthContextProvider value={authService}>
              <Component {...pageProps} />
            </AuthContextProvider>
          </ConfigContextProvider>
          <ToastContainer />
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </CacheProvider>
  )
}
