import { IUser } from '@/interface/user.interface'
import { CreateProfileData } from '@/pages/auth/create-profile'
import { Verification } from '@/pages/auth/verification-code'
import { VerificationCodeData } from '@/pages/auth/verification-method'
import { RegisterProps } from '@/pages/register'
import React, { useContext } from 'react'

type authContextType = {
  signIn: (username: string, password: string) => void
  forgotPassword: (username: string) => any
  resetPassword: () => any
  signOut: () => any
  register: (model: RegisterProps) => any
  sendVerificationCode: (data: VerificationCodeData) => any
  checkVerificationCode: (data: Verification) => any
  createProfile: (data: CreateProfileData) => any
  riskFactorQuestions: () => any
  user: IUser | null
  error: Error | null
  isLoading: boolean
}

const authContextDefaultValues: authContextType = {
  signIn: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  forgotPassword: (username: string) => undefined,
  resetPassword: () => undefined,
  signOut: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  register: (data: RegisterProps) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendVerificationCode: (data: VerificationCodeData) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkVerificationCode: (data: Verification) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createProfile: (data: CreateProfileData) => undefined,
  riskFactorQuestions: () => undefined,
  user: null,
  error: null,
  isLoading: false,
}

export const AuthContext = React.createContext<authContextType>(authContextDefaultValues)
// const AuthContext =  React.createContext<IAuthContextProps>(authContextDefaultValues)
// export const AuthContextProvider = (props: any) => {
//   const [currentUser, _] = useState<IUser | null>(null)
//
//   return (
//     <AuthContext.Provider
//       value={{
//         user: currentUser,
//       }}
//     >
//       {props.children}
//     </AuthContext.Provider>
//   )
// }

export const AuthContextProvider = ({ children, ...props }: any) => {
  return <AuthContext.Provider {...props}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext)
