import environment from '@/environment'
import axios, { AxiosRequestConfig } from 'axios'
import { logger } from '.'
import isClient from '@/libraries/isClient'
import { toast } from 'react-toastify'

let errorCount = 0
const instance = axios.create({
  baseURL: environment.ENDPOINT,
  timeout: 10000,
})

// Add a request interceptor
instance.interceptors.request.use(function (request) {
  const token = isClient && localStorage.getItem('token')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (token) request.headers.Authorization = `Bearer ${token}`
  return request
})

instance.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('token')
  }
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500

  if (!expectedError) {
    logger.log(error)
    if (errorCount === 0) toast('An unexpected error occurred.')
    errorCount++
  }

  return Promise.reject(error)
})

const get = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> =>
  (await instance.get(url, config)).data as T

const post = async <T, D = undefined>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T> =>
  (await instance.post(url, data, config)).data as T

const put = async <T, D = undefined>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T> =>
  (await instance.put(url, data, config)).data as T

const patch = async <T, D = undefined>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<T> =>
  (await instance.patch(url, data, config)).data as T

const Delete = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> =>
  (await instance.delete(url, config)).data as T

export default {
  get,
  post,
  put,
  patch,
  delete: Delete,
  postForm: instance.postForm,
}
