import environment from '@/environment'
import * as Sentry from '@sentry/nextjs'

function init() {
  Sentry.init({ dsn: environment.LOGGING })
}

function log(error: any) {
  Sentry.captureException(error)
}

export default { init, log }
